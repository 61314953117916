// ==============================
// ナビゲーション
// ==============================
import {BREAK_POINTS} from '../setting';

export default function () {

	const $searchTrigger = $('.js-search-trigger');
	const $searchTarget = $('.js-search-target');
	const $searchNavBg = $('.js-searchNav-bg');

  $(document).on('click','.js-gnav',setHeaderTab);

  function init() {
    if(window.innerWidth < BREAK_POINTS.ltSP) {
      $(function(){
        $('.js-gnav').on('click', function(){
          if ($(this).hasClass('is-active')) {
            $('body').css({overflow:'auto'});
            $('.js-gnav-wrapper').attr({'aria-expanded':'false'});
            $('.js-gnav-target').attr({'aria-hidden':'true'});
						$('.js-searchNav-bg').removeClass('is-open');
						$('.l-nav__body').removeClass('is-open');
          } else {
            $('body').css({overflow:'hidden'});
            $('.js-gnav-wrapper').attr({'aria-expanded':'true'});
            $('.js-gnav-target').attr({'aria-hidden':'false'});
						$('.js-searchNav-bg').addClass('is-open');
						$('.l-nav__body').addClass('is-open');
          };
        });
      });
    };
  };

  /** スマホナビ　開閉 **/
  function setHeaderTab() {
    $('.js-gnav').toggleClass('is-active');
  }

	/** headerナビ上の余白 **/
	function header(){
		const header_h = $('.l-header__inner').outerHeight()
		$('body').css({
			paddingTop: header_h
		})
	}

  /** カレント設定 **/
  function current(){
		const $current = $('.js-current');
		const $currentEfficacy = $('.l-nav__list li:nth-child(3) .js-current');
    $current.each(function(){
      const currentUrl = location.href;
      let target = $(this).attr('href');
      if(currentUrl.match(target)) {
				if(window.innerWidth > BREAK_POINTS.ltSP) {
					$current.removeClass('current');
					$(this).addClass('current');
				}
      }
      if(currentUrl.match(/efficacy/)) {
				if(window.innerWidth > BREAK_POINTS.ltSP) {
					$(this).removeClass('current');
					$currentEfficacy.addClass('current')
				}
      }
      if(location.hash=="#efficacy") {
				if(window.innerWidth > BREAK_POINTS.ltSP) {
					$('.l-nav__list li:first-child .js-current').addClass('current');
					$currentEfficacy.removeClass('current')
				}
      }
      if(currentUrl.match(/faq/)) {
				if(window.innerWidth > BREAK_POINTS.ltSP) {
					$(this).removeClass('current');
				}
      }
      if(currentUrl.match(/contact/)) {
				if(window.innerWidth > BREAK_POINTS.ltSP) {
					$(this).removeClass('current');
				}
      }
      if(currentUrl.match(/company/)) {
				if(window.innerWidth > BREAK_POINTS.ltSP) {
					$(this).removeClass('current');
				}
      }
      if(currentUrl.match(/privacy/)) {
				if(window.innerWidth > BREAK_POINTS.ltSP) {
					$(this).removeClass('current');
				}
      }
      if(currentUrl.match(/page/)) {
				if(window.innerWidth > BREAK_POINTS.ltSP) {
					$(this).removeClass('current');
				}
      }
    });

		$('.l-nav__list li:nth-child(3) .js-current').on('click',function() {
			$searchTrigger.removeClass('is-open');
			$searchTarget.fadeOut();
			$searchNavBg.removeClass('is-open');
			navOff();
		});
	}

	/** サブナビ **/
	// function subNav() {
	// 	const $subNav = $('.js-nav-sub')
	// 	const $subNavTrigger = $('.js-nav-sub-trigger')
	// 	const $subNavTarget = $('.js-nav-sub-target')

	// 	if(window.innerWidth > BREAK_POINTS.ltSP) {
	// 		$subNav.mouseover(function(){
	// 			$(this).children('.js-nav-sub-target').stop().fadeIn();
	// 		});
	// 		$subNav.mouseout(function(){
	// 			$(this).children('.js-nav-sub-target').stop().fadeOut();
	// 		});
	// 	}
	// 	if(window.innerWidth < BREAK_POINTS.ltSP) {
	// 		$subNav.on('click',function() {
	// 			$subNavTarget.slideToggle();
	// 			$subNavTrigger.toggleClass('is-open');
	// 		})
	// 	}
	// }

	// subナビ開いた際の高さ調整
	// function subNavH() {
	// 	const $subNav = $('.js-nav-sub')
	// 	const $subNavList = $('.l-nav__list')
	// 	if(window.innerWidth < BREAK_POINTS.ltSP) {
	// 		$subNav.on('click' , function() {
	// 			$subNavList.toggleClass('js-adjust-height')
	// 		});
	// 	}
	// }


	// headerナビ border設定
	function changeColor() {
		if(window.innerWidth > BREAK_POINTS.ltSP) {
			if($('#js-nav-border-on').hasClass('is-active')) {
				const timing = 140;
				const trigger1 = document.getElementById('js-nav-border-on');
				const trigger2 = document.getElementById('js-nav-border-off');
				const trigger1Y = trigger1.getBoundingClientRect().top;
				const trigger2Y = trigger2.getBoundingClientRect().top;

				if(trigger2Y - timing > 0 && 0 >= trigger1Y - timing) {
					$('.l-nav').css({borderBottom : "none"})
				}else {
					$('.l-nav').css({borderBottom : "1px solid #73a9d9"})
				}
			}
		}
	}

	// SPナビIOS高さ設定
	function setVh() {
		const vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	};

	// ナビ解除
	function navOff() {
		$('body').css({overflow:'auto'});
		$('.js-gnav').removeClass('is-active');
		$('.js-gnav-wrapper').attr({'aria-expanded':'false'});
		$('.js-gnav-target').attr({'aria-hidden':'true'});
		$('.l-nav__body').removeClass('is-open');
	}

	// サーチ
	function search() {

		$searchTrigger.on('click', function(){
			$searchTrigger.toggleClass('is-open');
			$searchTarget.fadeToggle();
			navOff();

			if($searchTrigger.hasClass('is-open')) {
				$searchNavBg.addClass('is-open');
			}else {
				$searchNavBg.removeClass('is-open');
			}
		});

		$searchNavBg.on('click', function(){
			$searchTrigger.removeClass('is-open');
			$searchTarget.fadeOut();
			$searchNavBg.removeClass('is-open');
			navOff();
		});

		$('.js-gnav').on('click',function() {
			$searchTrigger.removeClass('is-open');
			$searchTarget.fadeOut();
			$searchNavBg.removeClass('is-open');
		});
	}

	$(function(){
		current();
		// subNav();
	});

	$(window).bind('load scroll resize orientationchange', function () {
		init();
		header();
		changeColor();
		setVh();
		// subNavH();
	});

	$(window).on('load',function(){
		search()
	});
};


