export default function() {
	$(function(){
		var h = 0;
		$(".c-article-card__inner").each(function(){
			if($(this).height() > h){
				h = $(this).height();
			}
		});
		$(".c-article-card__inner").css("height", h + "px");
	});
}
