import {BREAK_POINTS} from '../setting';

export default function() {

	function faq() {
		const $qa_item = $(this).closest('.c-qa__item');
		const $qa_a = $('.c-qa__a');

		$qa_a.each(function() {
			if(window.innerWidth > BREAK_POINTS.ltSP) {
				if($(this).height() < 26) {
					$qa_item.addClass('c-qa__item--adjust')
				}else {
					$qa_item.removeClass('c-qa__item--adjust')
				}
			}
		})
	}

	$(window).bind('load scroll resize orientationchange', function () {
		faq();
	});
}
