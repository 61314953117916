import nav from "./module/nav";
import change from "./module/change"
import faq from "./module/faq";
import height from "./module/height";


$(function(){
  const headerHeight = $('.l-header__inner').outerHeight();
  const urlHash = location.hash;
  if(urlHash) {
    setTimeout(function(){
      const target = $(urlHash);
      const position = target.offset().top - headerHeight;
      $('body,html').stop().scrollTop(position);
    }, 100);
  }
  $('a[href^="#"]').click(function(){
    const href= $(this).attr("href");
    const target = $(href);
    const position = target.offset().top - headerHeight;
    $('body,html').stop().animate({scrollTop:position}, 500);
    return false;
  });
});

$(window).bind('load resize', function () {
	const windowHeight = $(window).height();
	const bodyHeight = $('body').height() + $('.l-header__inner').outerHeight();
	const adjustHeight = windowHeight - bodyHeight
	const mainHeight = $('main').height();
	if (windowHeight > bodyHeight) {
		$('main').css({
			height: mainHeight + adjustHeight,
		})
	}
});

nav();
change();
faq();
height();
